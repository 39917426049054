@font-face {
  font-family: "Kabel-Black-Regular";
  src: url('./fonts/Kabel-Black-Regular.otf');
}

body {
  background: #0B486B;
  font-family: "Kabel-Black-Regular";
  cursor: default;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 4vmin);
  color: #CFF09E;
  -webkit-text-stroke: 1px #333;
}